import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import ConfigurationCard from '../ConfigurationCard';
import Tab from '../../../core/components/Tab';
import Tabs from '../../../core/components/Tabs';
import FieldsNav, { FieldsNavHeader, useFieldsNav } from './FieldsNav';
import FieldsForm, { useFieldsForm } from './FieldsForm';
import { useForm } from 'react-hook-form';
import ProgressButton from '../../../core/containers/ProgressButton';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';

const useGeneralForm = ({ form, config }) => {
    const { t } = useTranslation('config');

    const formSections = [
        {
            name: 'automation',
            label: t('documentConfig.generalSections.automation'),
            formFields: [
                {
                    name: 'automation_enabled',
                    isNullable: false,
                    valueType: 'boolean',
                },
                {
                    name: 'header_confidence_threshold',
                    isNullable: false,
                    valueType: 'decimal',
                },
                {
                    name: 'line_item_confidence_threshold',
                    isNullable: false,
                    valueType: 'decimal',
                },
            ],
        },
        {
            name: 'dataFormatting',
            label: t('documentConfig.generalSections.dataFormatting'),
            formFields: [
                {
                    name: 'date_format',
                    isNullable: false,
                    valueType: 'string',
                },
                {
                    name: 'decimal_separator',
                    isNullable: false,
                    valueType: 'string',
                },
            ],
        },
        {
            name: 'unitConversion',
            label: t('documentConfig.generalSections.unitConversion'),
            formFields: [
                {
                    name: 'conversion_style',
                    isNullable: false,
                    valueType: 'str',
                },
                {
                    name: 'conversion_override_original_fields',
                    isNullable: false,
                    valueType: 'boolean',
                },
                {
                    name: 'best_effort_conversion',
                    isNullable: false,
                    valueType: 'boolean',
                },
                {
                    name: 'partition_specific_unit_conversion',
                    isNullable: false,
                    valueType: 'boolean',
                },
            ],
        },
        {
            name: 'validation',
            label: t('documentConfig.generalSections.validation'),
            formFields: [
                {
                    name: 'validation_checks',
                    isNullable: false,
                    valueType: 'string',
                },
                {
                    name: 'keywords_to_assistance',
                    isNullable: false,
                    valueType: 'string',
                },
            ],
        },
    ];

    const initialValues = {
        general: {
            automation: {
                automation_enabled: config?.automationEnabled,
                header_confidence_threshold: config?.headerConfidenceThreshold,
                line_item_confidence_threshold: config?.lineItemConfidenceThreshold,
            },
            dataFormatting: {
                date_format: config?.dateFormat,
                decimal_separator: config?.decimalSeparator || ',',
            },
            unitConversion: {
                conversion_style: config?.conversionStyle,
                conversion_override_original_fields: config?.conversionOverrideOriginalFields,
                best_effort_conversion: config?.bestEffortConversion,
                partition_specific_unit_conversion: config?.partitionSpecificUnitConversion,
            },
            validation: {
                validation_checks: config?.validationChecks || [],
                keywords_to_assistance: config?.keywordsToAssistance || [],
            },
        },
    };

    const documentTypeSpecificFormSection = {
        name: 'documentTypeSpecific',
        label: t('documentConfig.generalSections.documentTypeSpecific'),
        formFields: [],
    };

    const documentTypeSpecificInitialValues = {};

    if ('backfillingEnabled' in config) {
        documentTypeSpecificFormSection.formFields.push({
            name: 'backfilling_enabled',
            isNullable: false,
            valueType: 'boolean',
        });
        documentTypeSpecificInitialValues['backfilling_enabled'] = config.backfillingEnabled;
    }

    if ('variantExtractionExamples' in config) {
        documentTypeSpecificFormSection.formFields.push({
            name: 'variant_extraction_examples',
            isNullable: false,
            valueType: 'dict',
        });
        documentTypeSpecificInitialValues['variant_extraction_examples'] = config.variantExtractionExamples;
    }

    if (documentTypeSpecificFormSection.formFields.length > 0) {
        formSections.push(documentTypeSpecificFormSection);
        initialValues.general['documentTypeSpecific'] = documentTypeSpecificInitialValues;
    }

    useEffect(() => {
        // reset form values
        form.setValue('general', initialValues);
    }, [config]);

    const filteredFormSections = formSections
        .map((section) => {
            const filteredFields = section.formFields.filter((field) => {
                if (section.name === 'documentTypeSpecific') return true;

                const configKey = camelCase(field.name);
                return configKey && configKey in config;
            });

            // Only return sections that have at least one form field
            return filteredFields.length > 0 ? { ...section, formFields: filteredFields } : null;
        })
        .filter((section) => section !== null);

    return {
        initialValues,
        defaultValues: {},
        formSections: filteredFormSections,
    };
};

export const useFieldList = ({
    form,
    formName,
    fields,
    translationPrefix,
    getConfiguredFields,
    onCustomAddField,
    onSortChange,
}: any) => {
    const [configuredFieldNames, setConfiguredFieldNames] = useState([]);
    const [fieldsSort, setFieldsSort] = useControllableState([], undefined, onSortChange);

    const fieldsWithConfigurableOptions = fields.map((field) => ({
        ...field,
        options: field.options.filter((option) => !option.hasOwnProperty('configurable') || option.configurable),
    }));

    const availableFields = fields;
    const configuredFields = getConfiguredFields
        ? getConfiguredFields({ fieldsWithConfigurableOptions, configuredFieldNames })
        : fieldsWithConfigurableOptions.filter((field) => configuredFieldNames.includes(field.name));

    const { formSections, initialValues, defaultValues } = useFieldsForm({
        availableFields,
        configuredFields,
        translationPrefix,
    });

    useEffect(() => {
        const nextConfiguredFields = fieldsWithConfigurableOptions.filter((field) => field.enabled);
        setConfiguredFieldNames(nextConfiguredFields.map((field) => field.name));

        // only base fields, no subfields in sort
        nextConfiguredFields.sort((a, b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return 1;
            } else if (b.position === undefined) {
                return -1;
            } else {
                return a.position - b.position;
            }
        });
        setFieldsSort(nextConfiguredFields.filter((field) => !field.name.includes('__')).map((field) => field.name));

        // reset form values
        const nextFormValues = {};
        nextConfiguredFields.forEach((field) => {
            const [groupName, _] = field.name.split('__');
            if (!nextFormValues[groupName]) nextFormValues[groupName] = {};
            nextFormValues[groupName][field.name] = Object.fromEntries(
                field.options.map((option) => [option.name, option.value])
            );
        });
        form.setValue(formName, nextFormValues);
    }, [fields]);

    const getFieldAndSubfields = (fieldName) =>
        fields.filter((field) => field.name === fieldName || field.name.startsWith(`${fieldName}__`));

    const addField = (fieldName) => {
        let fieldNamesToAdd = null;

        if (onCustomAddField) {
            fieldNamesToAdd = onCustomAddField({ form, formName, configuredFieldNames, fieldName });
        } else {
            // if the selected field has subfields, add them as well
            fieldNamesToAdd = getFieldAndSubfields(fieldName)
                .filter((field) => !configuredFieldNames.includes(field.name))
                .map((field) => field.name);

            // update field on the form to use default values as initial values
            fieldNamesToAdd.forEach((fieldNameToAdd) => {
                const field = fieldsWithConfigurableOptions.find((field) => field.name === fieldNameToAdd);
                const groupName = field ? field.name.split('__')[0] : fieldNameToAdd;
                form.setValue(
                    `${formName}.${groupName}.${fieldNameToAdd}`,
                    Object.fromEntries(field.options.map((option) => [option.name, option.defaultValue]))
                );
            });
        }

        setConfiguredFieldNames((prev) => [...prev, ...fieldNamesToAdd]);
        setFieldsSort((prev) => [...fieldNamesToAdd, ...prev]);
    };

    const deleteField = (fieldName, subfield = undefined) => {
        const deletableFieldNames = getFieldAndSubfields(fieldName)
            .filter((field) => configuredFieldNames.includes(field.name))
            .map((field) => field.name);

        // 2 because the field itself is included in deletableFieldNames
        const isLastSubfield = deletableFieldNames.length === 2;

        // if the selected field has subfields, delete them as well
        // otherwise delete the field itself and if it is the last the whole group
        let fieldNamesToDelete = subfield && !isLastSubfield ? [`${fieldName}__${subfield}`] : deletableFieldNames;
        fieldNamesToDelete = fieldNamesToDelete.length === 0 ? [fieldName] : fieldNamesToDelete;

        setConfiguredFieldNames((prev) => prev.filter((fieldName) => !fieldNamesToDelete.includes(fieldName)));
        setFieldsSort((prev) => prev.filter((fieldName) => !fieldNamesToDelete.includes(fieldName)));

        const formValues = form.getValues();
        fieldNamesToDelete.forEach((fieldNameToDelete) => {
            const [groupName, _] = fieldNameToDelete.split('__');
            const groupValues = formValues[formName][groupName];
            delete groupValues[fieldNameToDelete];
            form.setValue(`${formName}.${groupName}`, groupValues);
        });
    };

    return {
        availableFields,
        configuredFields,
        addField,
        deleteField,
        fieldsSort,
        setFieldsSort,
        // form stuff
        formSections,
        initialValues,
        defaultValues,
    };
};

export const getHeaderFields = (fields) => {
    const baseFields = fields?.filter((field) => !field.name.includes('__')).map((field) => field.name) || [];

    return fields?.filter((field) => {
        const [parentKey] = field.name.split('__', 1);
        return baseFields.includes(parentKey);
    });
};

export const convertHeaderFormData = (formData, fieldsSort) => {
    const fields = Object.assign({}, ...Object.values(formData));

    return Object.entries(fields).map(([fieldName, fieldConfig]: any) => ({
        fieldName: fieldName,
        position: fieldsSort.indexOf(fieldName) >= 0 ? fieldsSort.indexOf(fieldName) : undefined,
        options: Object.entries(fieldConfig).map(([optionName, optionValue]) => ({
            name: optionName,
            value: optionValue,
        })),
    }));
};

export const getLineItemFields = (fields, itemType = 'line_items') => {
    return (
        fields
            ?.filter((field) => field.name.startsWith(`${itemType}__`))
            .map((field) => ({
                ...field,
                name: field.name.replace(`${itemType}__`, ''),
            })) || []
    );
};

export const convertLineItemFormData = (formData, fieldsSort, itemType = 'line_items') => {
    const fields = Object.assign({}, ...Object.values(formData));

    return Object.entries(fields).map(([fieldName, fieldConfig]: any) => ({
        fieldName: `${itemType}__${fieldName}`,
        position: fieldsSort.indexOf(fieldName) >= 0 ? fieldsSort.indexOf(fieldName) : undefined,
        options: Object.entries(fieldConfig).map(([optionName, optionValue]) => ({
            name: optionName,
            value: optionValue,
        })),
    }));
};

const GeneralTab = ({ formSections, form, className = undefined }) => {
    return (
        <div className={classnames('fields-config', className)}>
            <div className="fields-config__general">
                <FieldsForm
                    key="fields-form-general"
                    formName="general"
                    fieldName="general"
                    formSections={formSections}
                    form={form}
                />
            </div>
        </div>
    );
};

const HeaderTab = ({ form, formName, fields, onSortChange, className = undefined }) => {
    const { t } = useTranslation('config');

    const translationPrefix = 'assistance:headerView.fieldNames';
    const { availableFields, configuredFields, addField, deleteField, fieldsSort, defaultValues, formSections } =
        useFieldList({
            form,
            formName: formName,
            fields: fields,
            translationPrefix: translationPrefix,
            onSortChange,
        });

    const formFields = form.watch('header');
    const nav = useFieldsNav({
        configuredFields,
        availableFields,
        formFields,
        onSortChange,
        fieldsSort,
        addField,
        deleteField,
        translationPrefix,
    });
    const fieldsFormRef = useRef(null);

    // replace default confidences threshold in-place
    const generalValues = form.getValues()?.general?.general || {};
    const defaultConfidenceThreshold = generalValues?.automation?.['header_confidence_threshold'] || 0;
    const defaultDateFormat = generalValues?.dataFormatting?.['date_format'] || '';
    const defaultDecimalSeparator = generalValues?.dataFormatting?.['decimal_separator'] || '';
    Object.values(defaultValues?.[nav.activeField] || {}).forEach((field: any) => {
        if (field.confidence_threshold == undefined) {
            field.confidence_threshold = defaultConfidenceThreshold;
        }
        if (field.date_format == undefined) {
            field.date_format = defaultDateFormat;
        }
        if (field.decimal_separator == undefined) {
            field.decimal_separator = defaultDecimalSeparator;
        }
    });

    return (
        <div className={classnames('fields-config', className)}>
            <div className="fields-config__left">
                <FieldsNavHeader addableFields={nav.addableFields} onAddField={nav.onAddField} />
                <FieldsNav fields={nav.fields} fieldsFormRef={fieldsFormRef} />
            </div>

            <div className="fields-config__right">
                <div className="fields-config__header">
                    <h2 className="fields-config__title">
                        {nav.activeField
                            ? t('documentConfig.formHeader', { field: t(`${translationPrefix}.${nav.activeField}`) })
                            : ''}
                    </h2>
                </div>

                {Object.keys(formSections).length > 0 && (
                    <FieldsForm
                        key={`fields-form-${nav.activeField}`}
                        form={form}
                        formName={formName}
                        fieldName={nav.activeField}
                        formSections={formSections[nav.activeField]}
                        defaultValues={defaultValues[nav.activeField]}
                        hideFirstSectionLabel={true}
                        ref={fieldsFormRef}
                    />
                )}
            </div>
        </div>
    );
};

const LineItemTab = ({ form, formName, fields, onSortChange, className = undefined }) => {
    const { t } = useTranslation('config');

    const translationPrefix = 'assistance:itemsView.fieldNames';
    const { availableFields, configuredFields, addField, deleteField, fieldsSort, defaultValues, formSections } =
        useFieldList({
            form,
            formName,
            fields,
            translationPrefix: translationPrefix,
            onSortChange,
        });

    const formFields = form.watch(formName);
    const nav = useFieldsNav({
        availableFields,
        configuredFields,
        formFields,
        addField,
        deleteField,
        fieldsSort,
        onSortChange,
        translationPrefix,
    });
    const fieldsFormRef = useRef(null);

    // replace default confidences threshold in-place
    const generalValues = form.getValues()?.general?.general || {};
    const defaultConfidenceThreshold = generalValues?.automation?.['line_item_confidence_threshold'] || 0;
    const defaultDateFormat = generalValues?.dataFormatting?.['date_format'] || '';
    const defaultDecimalSeparator = generalValues?.dataFormatting?.['decimal_separator'] || '';
    Object.values(defaultValues?.[nav.activeField] || {}).forEach((field: any) => {
        if (field.confidence_threshold == undefined) {
            field.confidence_threshold = defaultConfidenceThreshold;
        }
        if (field.date_format == undefined) {
            field.date_format = defaultDateFormat;
        }
        if (field.decimal_separator == undefined) {
            field.decimal_separator = defaultDecimalSeparator;
        }
    });

    return (
        <div className={classnames('fields-config', className)}>
            <div className="fields-config__left">
                <FieldsNavHeader addableFields={nav.addableFields} onAddField={nav.onAddField} />
                <FieldsNav fields={nav.fields} fieldsFormRef={fieldsFormRef} />
            </div>

            <div className="fields-config__right">
                <div className="fields-config__header">
                    <h2 className="fields-config__title">
                        {nav.activeField
                            ? t('documentConfig.formHeader', { field: t(`${translationPrefix}.${nav.activeField}`) })
                            : ''}
                    </h2>
                </div>

                {Object.keys(formSections).length > 0 && (
                    <FieldsForm
                        key={`fields-form-${nav.activeField}`}
                        form={form}
                        formName={formName}
                        fieldName={nav.activeField}
                        formSections={formSections[nav.activeField]}
                        defaultValues={defaultValues[nav.activeField]}
                        hideFirstSectionLabel={true}
                        ref={fieldsFormRef}
                    />
                )}
            </div>
        </div>
    );
};

export const DocumentConfig = ({
    documentTypeName,
    config,
    refetch,
    channelId,
    itemTypes = ['line_items'],
    updateMutation,
}) => {
    const { t } = useTranslation('config');

    const [activeTab, setActiveTab] = useState('general');
    const [submitMessage, setSubmitMessage] = useState(null);

    const [updateDocumentConfig, { error, loading }] = useMutation(updateMutation);

    const [fieldsSort, setFieldsSort] = useState({});
    const handleSubmit = (values) => {
        const documentConfigFields = Object.assign({}, ...Object.values(values.general.general));
        const documentConfig = {
            options: Object.entries(documentConfigFields).map(([optionName, optionValue]: any) => {
                return {
                    name: optionName,
                    value: optionValue,
                };
            }),
        };

        const fieldConfigs = [
            ...convertHeaderFormData(values.header, fieldsSort[''] || []),
            ...(itemTypes.flatMap((itemType) =>
                convertLineItemFormData(values[camelCase(itemType)], fieldsSort[itemType] || [], itemType)
            ) || []),
        ];

        return updateDocumentConfig({
            variables: {
                channelId,
                documentType: documentTypeName,
                documentConfig,
                fieldConfigs,
            },
        })
            .then(() => {
                setSubmitMessage(t('documentConfig.submitMessage.success'));
                setTimeout(() => {
                    setSubmitMessage(null);
                }, 2000);
            })
            .catch(() => {
                setSubmitMessage(t('documentConfig.submitMessage.error'));
                setTimeout(() => {
                    setSubmitMessage(null);
                }, 2000);
            })
            .then(() => refetch());
    };

    const documentConfig: any = useMemo(() => {
        return config ? Object.fromEntries(config.options.map((option) => [camelCase(option.name), option.value])) : {};
    }, [config]);

    // first initialize empty form - at this point no field configs are loaded yet anyway
    const form = useForm({
        defaultValues: {
            general: {},
            header: {},
            ...(Object.fromEntries(itemTypes.map((key) => [camelCase(key), {}])) || {}),
        },
    });

    const generalForm = useGeneralForm({ form, config: documentConfig });
    const headerFields = useMemo(() => getHeaderFields(config?.fields || []), [config?.fields]);
    const itemFields = useMemo(
        () =>
            Object.fromEntries(
                itemTypes.map((itemType) => [itemType, getLineItemFields(config?.fields || [], itemType)])
            ),
        [config?.fields]
    );

    return (
        <ConfigurationCard
            title={t('documentConfig.title')}
            description={t('documentConfig.description')}
            active={!!config}
            className="configuration-card--no-padding-body"
        >
            <div className="fields-tabs">
                <Tabs>
                    <Tab
                        label={t('documentConfig.tabs.general')}
                        active={activeTab === 'general'}
                        onClick={() => {
                            setActiveTab('general');
                        }}
                    />
                    <Tab
                        label={t('documentConfig.tabs.headerFields')}
                        active={activeTab === 'header'}
                        onClick={() => {
                            setActiveTab('header');
                        }}
                    />
                    {itemTypes.map((itemType) => {
                        return (
                            <Tab
                                key={itemType}
                                label={t(`documentConfig.tabs.${camelCase(itemType)}Fields`)}
                                active={activeTab === camelCase(itemType)}
                                onClick={() => {
                                    setActiveTab(camelCase(itemType));
                                }}
                            />
                        );
                    })}
                </Tabs>
            </div>

            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <GeneralTab
                    form={form}
                    formSections={generalForm.formSections}
                    className={classnames(activeTab !== 'general' && '!hidden')}
                />

                <HeaderTab
                    form={form}
                    formName="header"
                    fields={headerFields}
                    onSortChange={(updatedSort) =>
                        setFieldsSort((fieldsSort) => ({ ...fieldsSort, ['']: updatedSort }))
                    }
                    className={classnames(activeTab !== 'header' && '!hidden')}
                />

                {itemTypes.map((itemType) => (
                    <LineItemTab
                        key={itemType}
                        form={form}
                        formName={camelCase(itemType)}
                        fields={itemFields[itemType]}
                        onSortChange={(updatedSort) =>
                            setFieldsSort((fieldsSort) => ({ ...fieldsSort, [itemType]: updatedSort }))
                        }
                        className={classnames(activeTab !== camelCase(itemType) && '!hidden')}
                    />
                ))}

                <div className="fields-form-footer">
                    {submitMessage && <span className="fields-form-footer__message">{submitMessage}</span>}
                    <ProgressButton type="submit" label={t('documentConfig.submitButton')} inProgress={loading} />
                </div>
            </form>
        </ConfigurationCard>
    );
};
