import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../core/utils/hooks/useDebounce';
import { Option } from '../../core_updated/components/Fields/AutoCompleteField';
import { useApolloClient } from '@apollo/client';
import { useAssistanceContext } from '../pages/Assistance/AssistanceContext';
import { SEARCH_MASTERDATA_ENTITY_FIELD } from '../../masterdata/queries';
import { camelCase } from 'lodash';
import { useFocusTrap } from '../../core/utils/hooks/useLocation';
import { useTranslation } from 'react-i18next';
import { handleOpenMasterdataBrowser } from '../../masterdata/containers/MasterdataBoundField';

const MASTERDATA_BROWSER_KEY = 'BROWSE';
export const MASTERDATA_DEFAULT_BROWSER_KEY = 'DEFAULT_BROWSE';
const MASTERDATA_BROWSER_KEYS = [MASTERDATA_BROWSER_KEY, MASTERDATA_DEFAULT_BROWSER_KEY];
const MASTERDATA_MAX_DROPDOWN_OPTIONS = 5;

const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

const HighlightedText = ({ highlight, children }) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = children.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

    return (
        <>
            {parts.map((part, i) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <b key={i} className="font-semibold bg-tertiary">
                        {part}
                    </b>
                ) : (
                    <span key={i}>{part}</span>
                )
            )}
        </>
    );
};

export const renderArticleIdOption =
    (articleIdName: string) =>
    (option: Option, value: string): React.ReactNode => {
        const articleIds = [option.articleId1, option.articleId2, option.articleId3];
        const articleId = option[camelCase(articleIdName)] || '';
        const articleDescription = option.description || option.articleDescription || '';
        const otherArticleIdsText = articleIds
            .filter((id) => id) // filter non-null values
            .filter((id) => id != articleId)
            .join(', ');

        return (
            <div className={option.disabled ? 'text-secondary' : ''}>
                {option.message || (
                    <>
                        <div className="text-primary">
                            <HighlightedText highlight={value}>{option.label || option.value || ''}</HighlightedText>
                        </div>
                        <div className="text-tertiary text-sm">
                            <HighlightedText highlight={value}>
                                {(!!otherArticleIdsText
                                    ? [otherArticleIdsText, articleDescription]
                                    : [articleDescription]
                                ).join('\n')}
                            </HighlightedText>
                        </div>
                    </>
                )}
            </div>
        );
    };

const joinAddressFromResult = (option: Option) => {
    return [
        option.name,
        option.companyName,
        option.streetAndNr,
        option.postcode,
        option.city,
        option.country,
        option.email,
        option.phone,
        option.misc,
    ]
        .filter((part) => part)
        .join(', ');
};

export const renderAddressOption = (option: Option, value: string): React.ReactNode => {
    return (
        <div>
            <div className="text-primary">
                <HighlightedText highlight={value}>{option.label || option.value}</HighlightedText>
            </div>
            <div className="text-tertiary text-sm">
                <HighlightedText highlight={value}>{joinAddressFromResult(option)}</HighlightedText>
            </div>
        </div>
    );
};

export const renderClientOption = (option: Option, value: string): React.ReactNode => {
    return (
        <div onMouseDown={option.onMouseDown}>
            <div className="text-primary">
                <HighlightedText highlight={value}>{option.label || option.value}</HighlightedText>
            </div>
            <div className="text-tertiary text-sm">
                <HighlightedText highlight={value}>{joinAddressFromResult(option)}</HighlightedText>
            </div>
        </div>
    );
};

export const renderContactOption = (option: Option, value: string): React.ReactNode => {
    return (
        <div>
            <div className="text-primary">
                <HighlightedText highlight={value}>{option.label || option.value}</HighlightedText>
            </div>
            <div className="text-tertiary text-sm">
                <HighlightedText highlight={value}>{`${option.fullName}`}</HighlightedText>
            </div>
        </div>
    );
};

export const renderFrameworkContractOption = (option: Option, value: string): React.ReactNode => {
    return (
        <div>
            <div className="text-primary">
                <HighlightedText highlight={value}>{option.label || option.value}</HighlightedText>
            </div>
            <div className="text-tertiary text-sm">
                <HighlightedText highlight={value}>{`${option.clientName} (${option.clientId})`}</HighlightedText>
            </div>
        </div>
    );
};

export const fetchLookupOptions =
    ({ client, lookupDefinitionId, lookupFieldName, partitionId, extraSearchTerms = {} }) =>
    (searchTerm: string, offset: number = 0, limit: number = 10) => {
        const parseItem = (node: any): Option => {
            return {
                value: node?.[camelCase(lookupFieldName)],
                ...node,
                // data is to support legacy browsable masterdata fields
                data: { _lookupEntity: node },
            };
        };

        return client
            .query({
                query: SEARCH_MASTERDATA_ENTITY_FIELD,
                variables: {
                    lookupDefinitionId,
                    targetField: lookupFieldName,
                    searchTerm,
                    partitionId: partitionId || null, // prevent sending empty value
                    from: offset,
                    size: limit,
                    kwargs: extraSearchTerms,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res: any) => {
                const results = res?.data?.assistanceSearchByField.edges.map((edge) => edge.node);
                return results.map(parseItem);
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    };

export const useLookupOptions = ({
    lookupDefinitionId,
    lookupFieldName,
    partitionId,
    searchTerm,
    extraSearchTerms,
    searchLookupOnEmptyValue,
    skip = false,
}: any) => {
    const [options, setOptions] = useState<Option[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const client = useApolloClient();
    const { record } = useAssistanceContext();
    const recordId = record?.id;

    const fetchOptions = useCallback(
        (searchTerm: string, offset: number = 0, limit: number = 10) => {
            setIsLoading(true);

            return fetchLookupOptions({
                client,
                lookupDefinitionId,
                lookupFieldName,
                partitionId,
                extraSearchTerms: extraSearchTerms,
            })(searchTerm, offset, limit)
                .then((options: Option[]) => {
                    setOptions(options);
                    return options;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [client, lookupDefinitionId, recordId, lookupFieldName, partitionId]
    );
    const debouncedFetchOptions = useDebounce(fetchOptions, 300);

    useEffect(() => {
        if (skip) return;

        if (!recordId || !lookupDefinitionId || (!searchLookupOnEmptyValue && searchTerm === '')) {
            setOptions([]);
            return;
        }

        // prevent showing stale results until debounced fetch is triggered
        setIsLoading(true);

        debouncedFetchOptions(searchTerm);
    }, [debouncedFetchOptions, searchTerm, searchLookupOnEmptyValue, lookupDefinitionId, recordId, skip]);

    return { options, isLoading };
};

export const useMasterdataBrowser = ({
    lookupType,
    lookupDefinitionId,
    lookupFieldName,
    partitionId,
    searchTerm,
    options,
    onSelectOption,
    onBlur,
    onFocus,
    isPartitionIdField = false,
    lookupMatchingProps = null,
}: any) => {
    const { handlers } = useAssistanceContext();
    const { closeMasterdataBrowser } = handlers;

    const { t } = useTranslation('masterdata');
    const { trapFocus } = useFocusTrap();

    const [blurred, setBlurred] = useState(false);
    const [disableBlur, setDisableBlur] = useState(false);

    useEffect(() => {
        if (blurred) {
            if (!disableBlur) {
                onBlur?.(null);
            }

            setBlurred(false);
            setDisableBlur(false);
        }
    }, [blurred]);

    const fetchOptions = fetchLookupOptions({
        client: useApolloClient(),
        extraSearchTerms: lookupMatchingProps?.extraSearchTerms,
        lookupDefinitionId,
        lookupFieldName,
        partitionId,
    });

    const openBrowser = () =>
        handleOpenMasterdataBrowser({
            handlers: handlers,
            partitionId: partitionId,
            isPartitionIdField: isPartitionIdField,
            searchTerm: searchTerm,
            lookupType: lookupType,
            getItems: fetchOptions,
            targetField: lookupFieldName,
            hasMatching: lookupMatchingProps?.hasMatching,
            matchingDocumentNumber: lookupMatchingProps?.matchingDocumentNumber,
            lookupLastSuccessfulImportDate: lookupMatchingProps?.lookupLastSuccessfulImportDate,
            onSelectRecord: (record) => {
                onSelectOption?.({
                    value: record[camelCase(lookupFieldName)],
                    ...record,
                });
                closeMasterdataBrowser();
                setBlurred(true);
            },
        });

    // Open up the MD browser using the default lookup type belonging to the field type (no matching lookup)
    const openBrowserDefault = () =>
        handleOpenMasterdataBrowser({
            handlers: handlers,
            partitionId: partitionId,
            isPartitionIdField: isPartitionIdField,
            searchTerm: searchTerm,
            lookupType: lookupMatchingProps?.lookupTypeDefault,
            getItems: lookupMatchingProps?.fetchOptions,
            targetField: lookupMatchingProps?.lookupFieldNameDefault,
            onSelectRecord: (record) => {
                onSelectOption?.({
                    value: record[camelCase(lookupMatchingProps?.lookupFieldNameDefault)],
                    ...record,
                });
                closeMasterdataBrowser();
                setBlurred(true);
            },
        });

    const showBrowser = options.length > MASTERDATA_MAX_DROPDOWN_OPTIONS;
    const browsableOptions = showBrowser
        ? [
              ...options.slice(0, MASTERDATA_MAX_DROPDOWN_OPTIONS),
              {
                  role: 'button',
                  label: t('browser.openBrowser'),
                  value: MASTERDATA_BROWSER_KEY,
              },
          ]
        : options;

    const displayOptions = lookupMatchingProps?.renderOptions
        ? lookupMatchingProps?.renderOptions(browsableOptions, searchTerm)
        : browsableOptions;

    const handleFocus = (e) => {
        trapFocus({ current: e.target });
        onFocus?.(e);
    };

    const handleBlur = () => {
        // delayed blur to allow for click events to be processed
        setBlurred(true);
    };

    const handleSelectOption = (selectedOption) => {
        // prevent the value from turning into the browser key
        if (!MASTERDATA_BROWSER_KEYS.includes(selectedOption.value)) {
            onSelectOption(selectedOption);
        }
    };

    const handleSelect = (event: CustomEvent) => {
        // prevent the value from turning into the browser key
        if (MASTERDATA_BROWSER_KEYS.includes(event.detail.value)) {
            event.preventDefault();

            setDisableBlur(true);

            event.detail.value == MASTERDATA_BROWSER_KEY ? openBrowser() : openBrowserDefault();
        }
    };

    return {
        onBlur: handleBlur,
        onFocus: handleFocus,
        onSelectOption: handleSelectOption,
        onSelect: handleSelect,
        options: displayOptions,
    };
};

export const useExtractText = (query, resultFieldName) => {
    const client = useApolloClient();
    const { record } = useAssistanceContext();
    const recordId = record?.id;

    return useCallback(
        (bbox, pageIndex) => {
            return client
                .query({
                    query: query,
                    variables: {
                        recordId,
                        bbox,
                        pageIndex,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((res) => res?.data?.[resultFieldName]?.text || '')
                .catch((err) => {
                    // Don't block whole view if invalid lookup keys
                    console.error(err);
                    return '';
                });
        },
        [client, recordId, query, resultFieldName]
    );
};
